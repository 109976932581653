import { GridItem, Image, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import NewBadge from '../Common/NewBadge'
import SpinnerCentered from '../Common/SpinnerCentered'
import { gridImageProps } from '../../constants/styles/movieListStyles'
import { cleanTitle } from '../../utils/cleanMovieTitle'

interface MovieListItemProps {
    idxItem: number
    movie: any
    needsConversion: boolean
    isNew: boolean
    handleOpenPlayer: (year: number, title: string, file: string, subtitle: string) => void
    isLoadingPlayer: boolean
    selectedMovie?: any
    moviePosters: any
}

const MovieListItem = ({
    idxItem,
    movie,
    needsConversion,
    isNew,
    handleOpenPlayer,
    isLoadingPlayer,
    selectedMovie,
    moviePosters
}: MovieListItemProps) => {
    return (
        <GridItem
            as={motion.div}
            position="relative"
            textAlign="center"
            borderRadius={['10px', '20px']}
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            whileHover={{
                scale: 1.05
            }}
            transition={{ duration: 0.3, delay: idxItem * 0.05 } as any}
            key={movie.title}
            onClick={() =>
                !needsConversion &&
                handleOpenPlayer(movie.year, movie.title, movie.files[0], movie.subtitles?.[0])
            }
            cursor={needsConversion ? 'not-allowed' : 'pointer'}
        >
            {isNew && <NewBadge />}
            <VStack align="center">
                {isLoadingPlayer &&
                    selectedMovie?.title === movie.title &&
                    selectedMovie?.year === movie.year && <SpinnerCentered />}

                <Image
                    {...gridImageProps}
                    src={
                        moviePosters[`${cleanTitle(movie.title)}_${movie.year}_poster`] ||
                        'https://via.placeholder.com/200x300?text=No+Poster'
                    }
                    alt={`${movie.title} poster`}
                    opacity={
                        (isLoadingPlayer &&
                            selectedMovie?.title === movie.title &&
                            selectedMovie?.year === movie.year) ||
                        needsConversion
                            ? 0.4
                            : 1
                    }
                />
                <Text fontSize="lg" fontWeight="regular">
                    {movie.title.replace(/\s?\(\d+\)/, '')}
                </Text>
            </VStack>
        </GridItem>
    )
}

export default MovieListItem
