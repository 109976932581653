import { Center, Icon, Text } from '@chakra-ui/react'
import { centerNotFoundProps, iconNotFoundProps } from '../../constants/styles/movieListStyles'
import { FaExclamationTriangle } from 'react-icons/fa'

const MovieNotFound = () => (
    <Center {...centerNotFoundProps}>
        <Icon {...iconNotFoundProps} as={FaExclamationTriangle} />
        <Text fontSize="lg" fontWeight="bold">
            No se encontró la película
        </Text>
        <Text fontSize="md">Intenta buscar con otro título.</Text>
    </Center>
)

export default MovieNotFound
