import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const NewBadge: React.FC = () => {
    return (
        <Box
            position="absolute"
            top="5px"
            right="15%"
            bg="red.500"
            color="white"
            px={2}
            py={1}
            borderRadius="md"
            fontWeight="bold"
            fontSize="sm"
            zIndex={1}
            boxShadow="lg"
        >
            <Text>NEW</Text>
        </Box>
    )
}

export default NewBadge
